.breadcrumbs.appV0 {
  /* background-color: #999; */
  font-size: 13px;
  color: #616161;
  font-weight: lighter;
}
.breadcrumbs.appV0 > a,
.breadcrumbs.appV0 > span {
  text-transform: capitalize;
  display: inline-block;
}
.breadcrumbs.appV0 a {
  color: #616161;
  font-weight: bold;
  text-decoration: none;
}
.breadcrumbs.appV0 > * {
  padding: 0 10px;
  border-right: 1px solid #616161;
}
.breadcrumbs.appV0 > *:first-child {
  padding-left: 0;
}
.breadcrumbs.appV0 > *:last-child {
  border: 0;
}
