.form-field.appV0 {
  height: 35px;
  background-color: #f6f4f4;
  border-radius: 20px;
}
.form-field.appV0 > input[type="text"] {
  border: 0;
  background-color: transparent;
  outline: none;
  padding-left: 17px;
}
.form-field.appV0 > mat-icon.right.icon {
  margin: 5px 8px 0 -24px;
}
