.card.appV0 {
  background-color: #fff;
}

.card.appV0 > .header {
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0 30px;
  height: 45px;
  background-image: linear-gradient(to right, #f6f4f4 10px, transparent 10px);
}

.card.appV0 > .header:first-child {
  margin-top: 0px;
}

.card.appV0 > .double.header {
  height: 98px;
}
.card.appV0 > .header > .double.title > * {
  margin: 0;
}

.card.appV0 > .header > h1,
.card.appV0 > .header > h2,
.card.appV0 > .header > h3,
.card.appV0 > .header > h4,
.card.appV0 > .header > h5,
.card.appV0 > .header > h6,
.card.appV0 > .header > span {
  margin: 0;
  line-height: 45px;
  font-size: 18px;
  font-weight: 500;
  color: #2e2e33;
}

.card.appV0 > .header > .double.title > h3 {
 font-size: 14px;
 font-weight: normal;
}
.card.appV0 > .header > .double.title > h4 {
  font-size: 24px;
  font-weight: 500;
}

.card.appV0 > .content {
  padding: 0 30px 30px 30px;
}
