.page.appV0.header {
  padding: 25px;
  height: 90px;
}

.page.appV0.header > .actions > * {
  margin-right: 24px;
}
.page.appV0.header > .actions > *:last-child {
  margin-right: 0;
}

.page.appV0.content {
  padding: 0 25px;
  overflow-x: auto;
}
