.cdk-overlay-pane.appV0 mat-dialog-container {
  overflow: visible;
}

.cdk-overlay-pane.appV0 mat-dialog-container .dialog.close {
  height: 50px;
}

.cdk-overlay-pane.appV0 mat-dialog-container .dialog.close {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretch flex-end;
  align-items: stretch;

  position: relative;
  top: 81px;
  left: 50px;
  height: 50px;
  margin-top: -50px;
}

.cdk-overlay-pane.appV0 mat-dialog-container .dialog.close > mat-icon {
  border-radius: 32px;
  padding: 13px;
  cursor: pointer;
  background-color: #fff;
}
