/**
 * Card header buttons
 */
.card.appV0 > .header > button.appV0.mat-button-base,
.header > button.appV0.mat-button-base,
button.appV0.mat-button-base {
  height: 32px;
  font-size: 10px;
  line-height: 27px;
  padding: 0 8px;
  font-weight: 600;
}
.card.appV0 > .header > button.appV0.mat-button-base mat-icon,
.header > button.appV0.mat-button-base mat-icon,
button.appV0.mat-button-base mat-icon {
  margin-right: 10px;
  font-size: 17px;
  line-height: 23px;
  width: 17px;
}
.card.appV0 > .header > button.appV0.mat-stroked-button,
.header > button.appV0.mat-stroked-button,
button.appV0.mat-stroked-button {
  border-radius: 7px;
  border: 2px solid currentColor;
}
/** Primary */
.card.appV0 > .header > button.appV0.mat-stroked-button.md-primary:not(.mat-button-disabled),
.header > button.appV0.mat-stroked-button.md-primary:not(.mat-button-disabled),
button.appV0.mat-stroked-button.md-primary:not(.mat-button-disabled) {
  border: 2px solid #3d85e3;
}
.card.appV0 > .header > button.appV0.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary,
.header > button.appV0.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary,
button.appV0.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #3d85e3;
}
/** Warn */
.card.appV0 > .header > button.appV0.mat-stroked-button.md-warn:not(.mat-button-disabled),
.header > button.appV0.mat-stroked-button.md-warn:not(.mat-button-disabled),
button.appV0.mat-stroked-button.md-warn:not(.mat-button-disabled) {
  border: 2px solid #e55151;
}
.card.appV0 > .header > button.appV0.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn,
.header > button.appV0.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn,
button.appV0.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #e55151;
}
