/* You can add global styles to this file, and also import other style files */


/* @import url('https://fonts.googleapis.com/css?family="acumin-pro"&display=swap'); */

@import '~ngx-print-element/styles.css';
:root {
    --light-blue: #2598e5;
    --grey-text: #7f7f7f;
}

.spaceHeight10px {
    height: 10px;
}

.spaceHeight20px {
    height: 20px;
}

.spaceHeight40px {
    height: 40px;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    overflow-y: scroll;
    background: #f6f4f4;
    box-shadow: inset 0 0 4px #f4f4f4;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: #b2b2b2;
    background: linear-gradient(to bottom, #dddddd, 20%, #b2b2b2, 80%, #dddddd);
    border-radius: 6px;
}

html {
    height: 100%;
    width: 100%;
    /* font-family: '"acumin-pro"', sans-serif; */
    font-family: "acumin-pro", sans-serif;
}

body {
    /* font-family: '"acumin-pro"', sans-serif; */
    font-family: "acumin-pro", sans-serif;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: stretch; */
    /* background-color: darkgreen; */
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    /* position: absolute; */
    /* top: 0; */
    /* bottom: 0; */
    /* left: 0; */
    /* right: 0; */
    /* width: 100%; 
  height: 100%; */
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: "acumin-pro", sans-serif;
    /* font-family: "acumin-pro", "Helvetica Neue", sans-serif; */
}


/* Alex Karampinakis custom classes for Room-Requests page */

.material-icons.color_blue {
    color: #3d85e3;
}

.material-icons.color_red {
    color: #e55151;
}

@media print {
    .no-print,
    .no-print * {
        display: none !important;
    }
}

.mat-badge-content {
    font-weight: 600;
    font-size: 12px;
    font-family: "acumin-pro", "Helvetica Neue", sans-serif
}

.mat-h1,
.mat-headline,
.mat-typography h1 {
    font: 400 24px/32px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 16px
}

.mat-h2,
.mat-title,
.mat-typography h2 {
    font: 500 20px/32px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 16px
}

.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
    font: 400 16px/28px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 16px
}

.mat-h4,
.mat-subheading-1,
.mat-typography h4 {
    font: 400 15px/24px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 16px
}

.mat-h5,
.mat-typography h5 {
    font: 400 calc(14px * 0.83)/20px "acumin-pro", "Helvetica Neue", sans-serif;
    margin: 0 0 12px
}

.mat-h6,
.mat-typography h6 {
    font: 400 calc(14px * 0.67)/20px "acumin-pro", "Helvetica Neue", sans-serif;
    margin: 0 0 12px
}

.mat-body-strong,
.mat-body-2 {
    font: 500 14px/24px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: normal
}

.mat-body,
.mat-body-1,
.mat-typography {
    font: 400 14px/20px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: normal
}

.mat-small,
.mat-caption {
    font: 400 12px/20px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: normal
}

.mat-display-4,
.mat-typography .mat-display-4 {
    font: 300 112px/112px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: -0.05em;
    margin: 0 0 56px
}

.mat-display-3,
.mat-typography .mat-display-3 {
    font: 400 56px/56px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: -0.02em;
    margin: 0 0 64px
}

.mat-display-2,
.mat-typography .mat-display-2 {
    font: 400 45px/48px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: -0.005em;
    margin: 0 0 64px
}

.mat-display-1,
.mat-typography .mat-display-1 {
    font: 400 34px/40px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0 0 64px
}

.mat-bottom-sheet-container {
    font: 400 14px/20px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: normal
}

.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 500
}

.mat-button-toggle {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif
}

.mat-card {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif
}

.mat-checkbox {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif
}

.mat-table {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif
}

.mat-calendar {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif
}

.mat-dialog-title {
    font: 500 20px/32px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: normal
}

.mat-expansion-panel-header {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif;
    font-size: 15px;
    font-weight: 400
}

.mat-expansion-panel-content {
    font: 400 14px/20px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: normal
}

.mat-form-field {
    font-size: inherit;
    font-weight: 400;
    line-height: 1.125;
    font-family: "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: normal
}

.mat-menu-item {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 400
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif;
    font-size: 12px
}

.mat-radio-button {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif
}

.mat-select {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif
}

.mat-select-trigger {
    height: 1.125em
}

.mat-slide-toggle-content {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif
}

.mat-slider-thumb-label-text {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 500
}

.mat-stepper-vertical,
.mat-stepper-horizontal {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif
}

.mat-tab-group {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif
}

.mat-tab-label,
.mat-tab-link {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 500
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
    font: 500 20px/32px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: normal;
    margin: 0
}

.mat-tooltip {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif;
    font-size: 10px;
    padding-top: 6px;
    padding-bottom: 6px
}

.mat-list-item {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif
}

.mat-list-option {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif
}

.mat-list-base .mat-subheader {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 500
}

.mat-list-base[dense] .mat-subheader {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 500
}

.mat-option {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif;
    font-size: 16px
}

.mat-optgroup-label {
    font: 500 14px/24px "acumin-pro", "Helvetica Neue", sans-serif;
    letter-spacing: normal
}

.mat-simple-snackbar {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif;
    font-size: 14px
}

.mat-tree {
    font-family: "acumin-pro", "Helvetica Neue", sans-serif
}


/*****************************************************
	Custom styling example bellow.
*****************************************************/

.iti {
    display: block !important;
    margin-bottom: 20px;
}

.iti .dropdown-menu.country-dropdown {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-color: #c7cace;
    margin-top: -1px;
}

.iti .iti__country-list {
    box-shadow: none;
    font-size: 14px;
    margin-left: 0;
    width: 244px;
    max-height: 170px;
}

.iti__flag-container.open+input {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.iti .search-container input {
    font-size: 14px;
    border-color: #c7cace;
    border-radius: 0;
    padding: 5px 10px;
}

.iti .search-container input:focus {
    outline: none;
}

@media screen and (max-width: 479px) {
    .iti .iti__country-list {
        width: 88.3vw;
    }
}

ngx-intl-tel-input input {
    height: 44px;
    margin-bottom: 20px;
    padding: 10px;
    border-style: solid;
    border-width: 1px;
    border-color: #c7cace;
    border-radius: 4px;
    font-size: 18px;
    font-size: 16px;
    /* width: 20px; */
    background-color: #ececec;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
    border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
    outline: none !important;
    border-color: #3498db;
    box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
    color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
    background-color: #e5eaf1;
}

.mat-form-field-black-border .mat-form-field-outline {
    color: black;
}

.full-width {
    width: 100%;
}

.fix-center-tabs .mat-tab-label-container {
    justify-content: center;
}

.fix-center-tabs .mat-tab-list {
    max-width: 220px;
}

.bold-tabs .mat-tab-label {
    font-weight: bold;
}