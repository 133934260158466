.custom-list-a.header.appV0 {
  font-weight: 500;
  margin: 10px 0;
}
.custom-list-a.header.appV0 > .left.icon {
  color: #3d85e3;
  margin-right: 4px;
}
.custom-list-a.header.appV0 > .right.icon {
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  background-color: #f6f4f4;
}
.custom-list-a.header.appV0 > .edit.icon {
  color: #3d85e3;
}
.custom-list-a.header.appV0 > .activated.icon {
  color: #51baa2;
}
.custom-list-a.header.appV0 > .deactivated.icon {
  color: #e55151;
}
.custom-list-a.header.appV0 > .activated.required.icon {
  color: #e55151;
}
.custom-list-a.header.appV0 > .deactivated.required.icon {
  color: #d4d4d7;
}

.custom-list-a.content.appV0 > .row {
  font-size: 18px;
  font-weight: 300;
  margin: 7.5px 0;
}
.custom-list-a.content.appV0 > .row.active {
  font-weight: 400;
}
.custom-list-a.content.appV0 > .row > .left.icon {
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  background-color: #f6f4f4;
}
.custom-list-a.content.appV0 > .row > .left.icon:last-child {
  margin-right: 6px;
}
.custom-list-a.content.appV0 > .row > .left.icon.edit {
  color: #3d85e3;
}
.custom-list-a.content.appV0 > .row > .left.icon.activated {
  color: #51baa2;
}
.custom-list-a.content.appV0 > .row > .left.icon.deactivated {
  color: #e55151;
}
.custom-list-a.content.appV0 > .row > .left.icon.activated.required {
  color: #e55151;
}
.custom-list-a.content.appV0 > .row > .left.icon.deactivated.required {
  color: #d4d4d7;
}

.custom-list-a.content hr.divider {
  margin: 5px 0 5px -10px;
  border: 0;
  border-bottom: 1px solid #d4d4d7;
}

/**
 * Card
 */
.card > .content .custom-list-a.header {
  margin-left: -29px;
}
