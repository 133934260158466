.cdk-overlay-pane.appV1 mat-dialog-container {
  overflow: visible;
  border-radius: 0;
}
.cdk-overlay-pane.appV1 mat-dialog-container .mat-dialog-title.header {
  height: 47px;
  margin: -24px -24px 20px -24px;
  padding: 0 30px;
  background-image: linear-gradient(to right, #209378 10px, transparent 10px);
  line-height: 47px;
}

.cdk-overlay-pane.appV1 mat-dialog-container .mat-dialog-title.header > .actions > * {
  margin-right: 20px;
}
.cdk-overlay-pane.appV1 mat-dialog-container .mat-dialog-title.header > .actions > *:last-child {
  margin-right: 0;
}

.cdk-overlay-pane.appV1 mat-dialog-container .dialog.close {
  height: 50px;
}

.cdk-overlay-pane.appV1 mat-dialog-container .dialog.close {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretch flex-end;
  align-items: stretch;

  position: relative;
  top: 70px;
  left: 49px;
  height: 50px;
  margin-top: -50px;
}

.cdk-overlay-pane.appV1 mat-dialog-container .dialog.close > mat-icon {
  border-radius: 32px;
  padding: 13px;
  cursor: pointer;
  background-color: #fff;
}
