button.appV1.mat-button-base {
  height: 42px;
  border-radius: 7px;
  font-size: 12px;
  font-weight: bold;
  padding: 0 10px;
}
button.appV1.mat-button-base.icon2right mat-icon {
  margin-left: 45px;
}

/**
 * Raised
 */
/** Primary */
button.appV1.mat-button-base.mat-raised-button.mat-primary:not(.mat-button-disabled) {
  background-color: #125cea;
}
/** Warn */
button.appV1.mat-button-base.mat-raised-button.mat-warn:not(.mat-button-disabled) {
  background-color: #e55151;
}

/**
 * Stroked
 */
button.appV1.mat-stroked-button {
  border: 2px solid;
}
button.appV1.mat-stroked-button::not(.mat-button-disabled) {
  border-color: #3d85e3;
}
