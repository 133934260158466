/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* MAT-DIALOG-CONTAINER RULE */
.user-actions mat-dialog-container {
  padding: 1%;
  width: 56rem;
  height: 24rem;
  /* background-color: green; */
  /* test border */
  /* border: 2px dashed goldenrod; */
}
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */


/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */
/* CDK-OVERLAY RULE */
.user-actions cdk-overlay-dark-backdrop {
  background: rgba(0,0,0,.85);
}
/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */