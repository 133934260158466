.mat-slide-toggle.mat-checked.appV0:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #d7d7d7;

  box-shadow: inset 2px 2px 1px 1px rgba(1, -1, 0, 0.2);
}

.mat-slide-toggle.appV0 .mat-slide-toggle-bar {
  background-color: #d7d7d7;

  box-shadow: inset 2px 2px 1px 1px rgba(1, -1, 0, 0.2);
}

.mat-slide-toggle.mat-checked.appV0:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #209378;
}

.mat-slide-toggle.appV0 .mat-slide-toggle-thumb {
  background-color: #b2b2b2;
}

.mat-slide-toggle.appV0 .mat-slide-toggle-content {
  font-size: 14px;
  font-weight: 500;
}


/**
 * Card header Slide toggle
 */
.card.appV0 > .header > .mat-slide-toggle.appV0 .mat-slide-toggle-content,
.header > .mat-slide-toggle.appV0 .mat-slide-toggle-content {
  font-size: 12px;
  font-weight: 500;
}


/**
 * Sunbeds Breadcrumb Slide toggle
 */
.slide-toggle-container > .mat-slide-toggle.appV0 .mat-slide-toggle-content,
.header > .mat-slide-toggle.appV0 .mat-slide-toggle-content {
  font-size: 12px;
  font-weight: 500;
}
