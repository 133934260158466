.pointer {
  cursor: pointer;
}

.cdisabled {
  filter: grayscale(1);
}

mat-progress-bar.appV0 {
  margin-bottom: -4px;
}
