/* MAT-DIALOG-CONTAINER RULE */
.web-checkin-edit mat-dialog-container {
  background-color: rgba(0, 0, 0, 0.1);
  height: 98vh;
  width: 66vw;
  position: absolute;
  left: 18em;
  bottom: 0em;
  top: 1em;
  right: 0em;
  padding: 0.1em;
  /* test border */
  /* border: 2px dashed black; */
  /* border: 2px dashed hotpink; */
  /*overflow: hidden;*/
}

/* CDK-OVERLAY RULE */
.web-checkin-edit cdk-overlay-dark-backdrop {
  background: rgba(0,0,0,0.7);
}


.web-checkin-edit mat-dialog-container .dialog.close {
  height: 50px;
}

.web-checkin-edit mat-dialog-container .dialog.close {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: stretch flex-end;
  align-items: stretch;

  position: relative;
  top: 81px;
  left: 34px;
  height: 50px;
  margin-top: -50px;
}

.web-checkin-edit mat-dialog-container .dialog.close > mat-icon {
  border-radius: 32px;
  padding: 13px;
  cursor: pointer;
  background-color: #fff;
}

.web-checkin-edit mat-dialog-container {
  overflow: visible;
}
